@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap');

body {
	background-color: #f5f5f4;
}

.landing-flexbox-container {
	display: flex;
	justify-content: center;
	flex-flow: row;
	align-items: center;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
}

.landing-flexbox-item {
	width: 675px;
	height: 408px;
	margin: 10px;
	border: 1px solid #dbdbda;
	background-color: #ffffff;
}

.landing-content {
	position: relative;
	top: 55px;
	font-size: 42px;
	font-family: Assistant;
	font-style: normal;
	font-weight: bold;
	line-height: 55px;
	color: #4f4d47;
	text-align: center;
}

.landing-logo {
	text-align: center;
	position: relative;
	right: 14px;
}

.landing-logo img {
	width: 200px;
	height: 220px;
}

.landing-spinner-container {
	text-align: center;
	position: relative;
	top: 30px;
}

.landing-spinner img {
	width: 80px;
	height: 80px;
}

.landing-spinner {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
	vertical-align: middle;
	-ms-flex-line-pack: center;
	align-content: center;
	width: 80px;
	height: 80px;
	display: inline-block;
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(315deg);
		transform: rotate(315deg);
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}