/* body.error-body {
	background-color: #ffffff;
} */

.error-flexbox-container {
    display: flex;
    flex-flow: row;
    background: #FFFFFF;
    justify-content: center;
    box-sizing: border-box;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.error-nav-gradient {
    position: absolute;
    height: 6px;
    left: 0px;
    right: 0px;
    top: 0px;
    background: linear-gradient(90deg, #5BBBEB 2.04%, #0677D1 98.66%);
}

.error-logo {
    text-align: left;
    position: absolute;
    left: 30px;
    top: 19px;
}

.error-container {
    width: 853px;
    height: 100vh;
    margin: 10px;
    background-color: #FFFFFF;
    font-family: Assistant;
    padding-top: 80px;
}

@media screen and (min-width: 600px) {
    .error-container {
        min-width: 680px;
        padding-top: 180px;
    }
}

.error-vector img {
    width: 53px;
    height: 53px;
}

.error-title {
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    position: relative;
    top: 25px;
    color: #4F4D47;
}

@media screen and (min-width: 600px) {
    .error-title {
        font-size: 52px;
    }
}

.error-title p {
    margin: 0;
}

.error-subtitle {
    font-size: 26px;
    font-style: normal;
    position: relative;
    top: 1px;
    color: #4F4D47;
    margin-bottom: 40px;
}

@media screen and (min-width: 600px) {
    .error-subtitle {
        font-size: 32.7px;
    }
}

.error-type {
    font-size: 20.4px;
    font-style: normal;
    font-weight: normal;
    color: #4F4D47;
    position: relative;
    top: 70px;
}

.error-line-1 {
    left: 0px;
    top: 85px;
}

.error-line-2 {
    left: 0px;
    top: 224px;
}

.error-btn-master {
    color: #0677D1;
    position: relative;
    width: 190px;
    height: 68px;
    border: 1px solid #DBDBDA;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    padding: 22px;
    font-size: 19px;
    font-style: normal;
    font-weight: bold;
}


.error-contact {
    font-size: 20.4px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: #4F4D47;
    top: 540px;
}

.error-help-links {
    font-size: 20.4px;
    top: 330px;
    font-style: normal;
    font-weight: normal;
}

a {
    color: #0677D1;
    line-height: 40px;
}

#loginLink {
    text-decoration: none;
}